<template>
  <div class="tree-menu" :style="computedStyle">
    <div v-if="section.section_name" class="node-title" @click="toggleChildren">
      {{ section.section_name }} <font-awesome-icon icon="sort-down" />
    </div>
    <div class="node-description" v-if="section.description && showChildren">
      {{ section.description }}
    </div>
    <p v-if="section.name" class="vedio-item">
      {{ section.name }}
      <a :href="section.url" target="_blanck">
        شاهد الفيديو <font-awesome-icon :icon="['fab', 'youtube']" />
      </a>
    </p>
    <tree-menu
      v-if="showChildren"
      v-for="(section, index) in section.sections"
      :key="index"
      :section="section"
      :depth="depth + 1"
      style="border: 1px solid rgba(0, 0, 0, 0.080)"
    ></tree-menu>
  </div>
</template>

<script>
export default {
  props: ["section", "depth"],
  data() {
    return { showChildren: false };
  },
  name: "tree-menu",
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
    }
  },
  computed: {
    computedStyle() {
      return `padding: ${this.depth * 10}px;`;
    }
  }
};
</script>

<style scoped lang="scss">
.tree-menu {
  direction: rtl;
  user-select: none;
  .node-title {
    width: 100%;
    margin-bottom: 5px;
    height: 50px;
    display: flex;
    align-content: center;
    align-items: center;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    padding: 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    cursor: pointer;
  }
  .node-description {
    margin: 10px auto 10px auto;
    font-size: 18px;
  }
  .vedio-item {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    a {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    }
  }
}
</style>

<template>
  <div class="m-wrapper">
    <div>
      <tree
        class="service-info-section"
        v-for="(section, index) in sections"
        :key="index"
        :section="section"
        :depth="0"
      ></tree>
    </div>
  </div>
</template>

<script>
import tree from "../../components/utils/tree";
export default {
  components: {
    tree
  },
  data() {
    return {
      sections: [
        {
          section_name: "متجر مجيب Mujeeb Shop",
          description:
            "يمكنك من خلال متجر مجيب أن تقوم بإنشاء متجرٍ لمنتجاتك أو معرضٍ لخدماتك وإدارة هذه المنتجات وتعديل مواصفاتها وأسعارها بسهولة وسرعة، اختر التعليمات حسب قالب المجيب الذي اخترته:",
          section: true,
          sections: [
            {
              section_name: "قالب المتجر",
              section: true,
              sections: [
                {
                  name: "تفعيل المتجر الآلي",
                  url: "https://youtu.be/Hg-EUb3PDPo"
                },
                {
                  name: "إضافة فئة",
                  url: "https://youtu.be/UK1X74KJS0s"
                },
                {
                  name: "تعديل فئة",
                  url: "https://youtu.be/hOpet8VCMkY"
                },
                {
                  name: "حذف فئة",
                  url: "https://youtu.be/Fhxs2uG43U4"
                },
                {
                  name: "التعديل على ترتيب الفئات",
                  url: "https://youtu.be/iHJZ58W3cpY"
                },
                {
                  name: "إضافة منتج",
                  url: "https://youtu.be/WtzwBr0zxSA"
                },
                {
                  name: "تعديل منتج",
                  url: "https://youtu.be/i7j7ErunFl0"
                },
                {
                  name: "حذف منتج",
                  url: "https://youtu.be/jsUkphmw1zY"
                }
              ]
            }
            // {
            //   section_name: 'قالب الخدمات',
            //   section: true,
            //   sections: [
            //     {
            //       name: 'تفعيل المتجر الآلي',
            //       url: 'https://youtu.be/UK1X74KJS0s'
            //     },
            //     {
            //       name: 'إضافة فئة',
            //       url: 'https://youtu.be/UK1X74KJS0s'
            //     },
            //     {
            //       name: 'تعديل فئة',
            //       url: 'https://youtu.be/hOpet8VCMkY'
            //     },
            //     {
            //       name: 'حذف فئة',
            //       url: 'https://youtu.be/Fhxs2uG43U4'
            //     },
            //     {
            //       name: 'التعديل على ترتيب الفئات',
            //       url: 'https://youtu.be/iHJZ58W3cpY'
            //     },
            //     {
            //       name: 'إضافة منتج',
            //       url: 'https://youtu.be/WtzwBr0zxSA'
            //     },
            //     {
            //       name: 'تعديل منتج',
            //       url: 'https://youtu.be/i7j7ErunFl0'
            //     },
            //     {
            //       name: 'حذف منتج',
            //       url: 'https://youtu.be/jsUkphmw1zY'
            //     }
            //   ]
            // }
          ]
        },
        {
          section_name: "معلومات التواصل مع صفحتك",
          description:
            "يمكنك هنا إضافة معلومات التواصل مع شركتك ، كأرقام الهاتف والعنوان مستخدماً الخرائط لوصول أسهل وأوضح",
          section: true,
          sections: [
            {
              name: "إضافة معلومات التواصل مع الصفحة",
              url: "https://youtu.be/BnzMEQviy1Q"
            }
          ]
        }
      ]
    };
  }
};
</script>

<style>
.service-info-section {
  direction: rtl;
  margin-bottom: 20px;
}
</style>
